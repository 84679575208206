import React from 'react';
import classNames from 'classnames';

import styles from './collection-layout.module.scss';

export const CollectionWrapper = ({
  layout = '2-col',
  extraClass,
  children
}) => {
  const wrapperClasses = {
    [styles.wrapper]: true,
    [styles.l3Col]: layout === '3-col',
    [styles.l4Col]: layout === '4-col',
    [extraClass]: extraClass
  };

  return <div className={classNames(wrapperClasses)}>{children}</div>;
};

export const CollectionItem = ({ flex = false, extraClass, children }) => {
  const itemClasses = {
    [styles.item]: true,
    [styles.itemFlex]: flex === true,
    [extraClass]: extraClass
  };

  return <div className={classNames(itemClasses)}>{children}</div>;
};
