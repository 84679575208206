import React from 'react';
import SEO from '../components/seo';
import PageLayout from '../components/page-layout';
import { ContentWrapper, ContentContainer } from '../components/content-layout';
import ContentBlock from '../components/content-block';
import {
  CollectionWrapper,
  CollectionItem
} from '../components/collection-layout';
import Button from '../components/button';
import MagGlass from '../components/header/assets/search-icon.svg';

import styles from './index.module.scss';

const IndexPage = () => (
  <PageLayout>
    <SEO title="Home" />
    <ContentWrapper>
      <ContentContainer>
        <ContentBlock machine_name={'home'}></ContentBlock>
        <CollectionWrapper extraClass={styles.wrapper}>
          <CollectionItem extraClass={styles.item} flex={true}>
            <Button
              to={`/search`}
              color={`blue`}
              withIcon={true}
              textStyle={'bold'}
              className={styles.button}
            >
              <MagGlass />
              <span>Search Interventions</span>
            </Button>
          </CollectionItem>
          <CollectionItem extraClass={styles.item} flex={true}>
            <Button to={`/about/project`} className={styles.button}>
              <span>More Information</span>
            </Button>
          </CollectionItem>
        </CollectionWrapper>
      </ContentContainer>
    </ContentWrapper>
  </PageLayout>
);

export default IndexPage;
